import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Profile from "@/views/Profile.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Message from "@/views/Message.vue";
import Templates from "@/views/Templates.vue";
import DmsUsers from "@/views/DmsUsers";
import AddTemplate from "@/views/AddTemplate.vue";
import TemplateDetails from "@/views/TemplateDetails.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/message",
    name: "Message",
    component: Message,
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
  },
  {
    path: "/dms",
    name: "DmsUsers",
    component: DmsUsers,
  },
  {
    path: "/template-details/:templateId",
    name: "TemplateDetails",
    component: TemplateDetails,
  },
  {
    path: "/addTemplate",
    name: "AddTemplate",
    component: AddTemplate,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
