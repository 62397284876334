<template>
  <!-- changelog -->
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 452.739 452.739"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>messages</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-1869.000000, -293.000000)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <g transform="translate(1716.000000, 291.000000)">
          <g id="office" transform="translate(153.000000, 2.000000)">
            <path
              class="color-background"
              d="M403.109,16.447H49.63C22.211,16.447,0,38.671,0,66.077v224.921c0,27.406,22.211,49.63,49.63,49.63h19.225l-39.253,78.738
	c-2.809,4.41-2.14,10.158,1.586,13.783c2.125,2.095,4.905,3.143,7.699,3.143c2.097,0,4.25-0.611,6.128-1.863l163.531-93.801h194.563
	c27.42,0,49.63-22.224,49.63-49.63V66.077C452.739,38.671,430.528,16.447,403.109,16.447z M124.311,210.947
	c-18.225,0-33-14.775-33-33s14.775-33,33-33c18.226,0,33,14.775,33,33S142.536,210.947,124.311,210.947z M226.37,210.947
	c-18.226,0-33-14.775-33-33s14.774-33,33-33c18.225,0,33,14.775,33,33S244.595,210.947,226.37,210.947z M328.428,210.947
	c-18.225,0-33-14.775-33-33s14.775-33,33-33c18.225,0,33,14.775,33,33S346.654,210.947,328.428,210.947z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
