<template>
  <div class="card mb-4 mt-3">
    <div class="card-header pb-0">
      <h6>Список сотрудников</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2 pe-2">
      <div class="input-group pt-2 pb-2 ps-3 pe-1">
        <input
          type="text"
          class="form-control"
          placeholder="Поиск сотрудника..."
          v-model.trim="fullName"
        />
      </div>
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ФИО
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Должность
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Статус
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in filteredList"
              :key="user.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <soft-avatar
                        :img="avatar"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{user.fullName}}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{user.email}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="position text-xs font-weight-bold mb-0">{{user.position}}</p>
                  <p class="text-xs text-secondary mb-0">{{user.mobileNumber}}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <soft-badge v-bind:color="user.isActive ? 'success' : 'secondary'" variant="gradient" size="sm"
                    >{{user.isActive ? 'Подключен' : 'Не подключен'}}</soft-badge
                  >
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import Loader from "@/components/Loader.vue";
import avatar from "../../assets/img/default-avatar.png";
import { defaultConfig } from "../../api/config.js";
export default {
  name: "authors-table",
  data() {
    return {
      avatar,
      usersList: [],
      fullName: '',
      isLoading: false,
    };
  },
  computed: {
    filteredList() {
      return this.usersList.filter(el => {
        return el.fullName.toLowerCase().indexOf(this.fullName.toLowerCase()) !== -1;
      })
    }
  },
  mounted() {
    this.getEmployee();
  },
  components: {
    SoftAvatar,
    SoftBadge,
    Loader
  },
  methods: {
    getEmployee() {
      this.isLoading = true;
      var self = this;
      defaultConfig.get('/employee/all')
        .then(function (response) {
          self.isLoading = false;
          self.usersList = response.data.result;
        })
        .catch( function (err) {
          self.isLoading = false;
          console.log("error", err.message);
        })
    },
  },
};
</script>
<style>
  .position {
    word-break: normal;
    white-space: normal;
  }
</style>