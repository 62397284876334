<template>
  <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
    <div class="card card-blog card-plain">
      <div class="px-1 pb-0 card-body">
        <p class="mb-2 text-sm text-gradient text-dark">{{ label }}</p>
        <a href="javascript:;">
          <h5>{{ title }}</h5>
        </a>
        <p class="mb-4 text-sm">
          {{ description }}
        </p>
        <div class="d-flex align-items-center justify-content-between">
          <button
            type="button"
            class="mb-0 btn btn-sm"
            :class="`btn-outline-${action.color}`"
            @click="navigateToTemplateDetails(templateId)"
          >
            {{ action.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultProjectCard",
  props: {
    templateId: {
      type: Number,
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Default Project Card",
    },
    description: {
      type: String,
      default: "",
    },
    action: {
      type: Object,
      route: String,
      color: String,
      label: String,
      default: () => {},
    },
  },
  methods: {
    navigateToTemplateDetails(id) {
      this.$router.push(`/template-details/${id}`);
    },
  },
};
</script>
<style scoped>
p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
</style>