<template>
  <transition name="modal-fade">
    <div v-if="show" class="modal-overlay" @click.self="close">
      <div class="modal-window" @click.stop>
        <img :src="this.isSuccessfull ? success : failed" alt=""/>
        <h3>{{this.title}}</h3>
        <h5>{{this.description}}</h5>
        <soft-button color="dark" variant="gradient" @click="close">Ок</soft-button>
      </div>
    </div>
  </transition>
</template>

<script>
import SoftButton from "./SoftButton.vue";
import success from "@/assets/img/success-icon.png";
import failed from "@/assets/img/failed-icon.png";

export default {
  name: "Modal",
  data() {
    return {
      show: false,
      title: "",
      description: "",
      isSuccessfull: true,
      success,
      failed
    };
  },
  components: {
    SoftButton,
  },
  methods: {
    close() {
      this.show = false;
      this.title = "";
      this.description = "";
    },
  },
}
</script>
<style scoped>
  .modal-overlay {
  position: fixed;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000da;
  z-index: 10000;
}
.modal-window {
  text-align: center;
  background-color: white;
  padding: 45px;
  max-width: 480px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
button {
  margin-top: 5%;
  width: 60%;
}
img {
  width:10%;
  margin-bottom: 5%;
}
h3,h5 {
  font-family: Gotham Pro;
}
</style>