<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="row">
              <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                <place-holder-card
                  :title="{ text: 'Добавить шаблон', variant: 'h5' }"
                  @click="navigateToAddTemplate"
                />
              </div>
              <default-project-card
                v-for="template in this.templates"
                :templateId=template.templateId
                :key="template.templateId"
                :title="template.title"
                :label='"Шаблон #" + template.templateId'
                :description="template.description"
                :action="{
                  color: 'warning',
                  label: 'Подробнее...',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DefaultProjectCard from "./components/DefaultProjectCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import { defaultConfig } from "@/api/config.js";

export default {
  name: "Templates",
  data() {
    return {
      templates: []
    }
  },
  components: {
    DefaultProjectCard,
    PlaceHolderCard,
  },
  mounted() {
    document.querySelector("#sidenav-main").setAttribute("data-color", "dark");
    if (!localStorage.getItem('loggedIn')) {
      this.$router.push('/sign-in')
    }
    this.getTemplates()
  },
  beforeMount() {
    this.$store.state.mcolor = 'card-background-mask-dark'
  },
  methods: {
    navigateToAddTemplate() {
      this.$router.push('/addTemplate');
    },
    getTemplates() {
      this.isLoading = true;
      var self = this;
      defaultConfig.get('/templates')
        .then(function (response) {
          self.isLoading = false;
          self.templates = response.data.result;
          console.log("templates", response.data.result);
        })
        .catch( function (err) {
          self.isLoading = false;
          console.log("error", err.message);
        })
    },
  }
}
</script>