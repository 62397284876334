<template>
  <div>
    <div class="py-4 container-fluid">
      <div class="row ps-3 pe-3 mb-2">
        <div class="col-12 col-md-6 mt-2 mb-2">
          <label>Тип рассылки</label>
          <select
            class="form-control select form-select"
            id="sendingType"
            name="sendingType"
            v-model="selectedSendingType"
          >
            <option v-bind:value="0" disabled hidden>Выберите тип рассылки...</option>
            <option v-bind:value="1">Сообщение без вложения</option>
            <option v-bind:value="2">Сообщение с вложением</option>
          </select>
          <p class="errorBlock" v-if="v$.selectedSendingType.$error"> {{ v$.selectedSendingType.$errors[0].$message }} </p>
        </div>
        <div class="col-12 col-md-6 mt-2 mb-2">
          <label>Аудитория рассылки</label>
          <select
            class="form-control select form-select"
            id="audienceType"
            name="audienceType"
            v-model="selectedAudienceType"
          >
            <option v-bind:value="0" disabled hidden>Выберите аудиторию рассылки...</option>
            <option v-bind:value="1">Все сотрудники</option>
            <option v-bind:value="2">Группа сотрудников</option>
            <option v-bind:value="3">Сотрудник</option>
          </select>
          <p class="errorBlock" v-if="v$.selectedAudienceType.$error"> {{ v$.selectedAudienceType.$errors[0].$message }} </p>
        </div>
      </div>
      
      <transition name="fade">
        <div class="row ps-3 pe-3 mb-2">
          <div v-show="selectedSendingType == '2'" class="col-12 col-md-6 mt-2 mb-2">
            <label>Выберите вложение</label>
            <div class="row">
              
              <div class="col-10">
                <label class="text-normal">
                  *Допустимые форматы вложений - jpg, png, mp3, mpeg4, pdf, docx, xlsx. Размер вложений не должен превышать 50Мб.
                </label>
              </div>
              <div class="col-2 mt-2 mb-2">
                <input @change="getFileName()" type="file" name="file" id="file" ref="file" class="inputfile"/>
                <label class="ms-3 me-3 mt-1 mb-2 fa fa-upload attachment-label" for="file"/>
              </div>
            </div>
          </div>
          <div v-show="selectedAudienceType == 3" class="col-12 col-md-6 mt-2 mb-2">
            <label>Выбранный сотрудник</label>
            <select
              class="form-control select form-select"
              id="user"
              name="user"
              v-model="selectedUser"
            >
              <option 
                v-for="option in options()"
                v-bind:key="option.tgChatId"
                :value="option.tgChatId"
                :disabled="option.tgChatId == '0'"
                :hidden="option.tgChatId == '0'">
                  {{option.fullName}}
              </option>
            </select>
            <p class="errorBlock" v-if="v$.selectedUser.$error"> {{ v$.selectedUser.$errors[0].$message }} </p>
          </div>
        </div>
      </transition>
      
      <div class="row ps-3 pe-3 mt-4 mb-2">
        <div class="col-12">
          <input
            class="form-control"
            id="title"
            type="email"
            placeholder="Заголовок"
            name="title"
            v-model="title"
          />
          <p class="errorBlock" v-if="v$.title.$error"> {{ v$.title.$errors[0].$message }} </p>
          <textarea 
            id="description"
            class="form-control mt-2 mb-2"
            rows="5"
            placeholder="Напишите здесь ваше сообщение..."
            name="description"
            v-model="description"/>
            <span class="errorBlock" v-if="v$.description.$error"> {{ v$.description.$errors[0].$message }} </span>
        </div>
      </div>
      
      <div class="row ps-3 pe-3 mb-2">
        <div class="col-12 col-md-6">
          <label v-show="this.attachmentName !== ''" class="attachment-name">
            Вложение: "{{ this.attachmentName }}"
          </label>
        </div>
        <div class="col-12 col-md-6 text-end">
          <soft-button class="me-1" @click="saveTemplate(this.$route.params.templateId)" color="secondary" variant="gradient">
            Сохранить
          </soft-button>
          <soft-button class="me-1" @click="deleteTemplate(this.$route.params.templateId)" color="danger" variant="gradient">
            Удалить
          </soft-button>
        </div>
      </div>
    </div>
    <modal ref="modal" />
    <loader :is-visible="isLoading"/>
  </div>
</template>

<script>
  import SoftButton from "@/components/SoftButton.vue";
  import Modal from "@/components/Modal.vue";
  import axios from 'axios';
  import Loader from "@/components/Loader.vue";
  import useValidate from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';
  import { defaultConfig } from "../api/config.js";
  const isSendingTypeSelected = (value) => value !== 0;
  const isAudienceSelected = (value) => value !== 0;
  const isUserSelected = (value, vm) => (value + 3 !== vm.selectedAudienceType);

  export default {
    name: "template-details",
    data() {
      return {
        v$: useValidate(),
        iconBackground: "bg-gradient-dark",
        title: "",
        description: "",
        selectedSendingType: 0,
        selectedAudienceType: 0,
        selectedUser: 0,
        attachmentName: "",
        isNewAttachment: false,
        isLoading: false,
        activeUsers: [],
        maxFileSize: 52428800,
      };
    },
    components: {
      SoftButton,
      Modal,
      Loader
    },
    mounted() {
      console.log('template-details', this.$route.params.templateId);
      document.querySelector("#sidenav-main").setAttribute("data-color", "dark");
      if (!localStorage.getItem('loggedIn')) {
        this.$router.push('/sign-in')
      }
      this.getActiveUsers();
      this.getTemplateDetails(this.$route.params.templateId);
    },
    beforeMount() {
      this.$store.state.mcolor = 'card-background-mask-dark'
    },
    methods: {
      options(){
        let mutableList = [];
        mutableList.push({'tgChatId': '0', 'fullName': 'Выберите сотрудника'});
        this.activeUsers.forEach((item) => {
          mutableList.push({'tgChatId': item.tgChatId, 'fullName': item.fullName})
        });
        return mutableList;
      },
      getFileName() {
        this.file = this.$refs.file.files[0];
        this.attachmentName = this.$refs.file.files[0].name;
        this.isNewAttachment = true;
      },
      getActiveUsers() {
        this.isLoading = true;
        var self = this;
        defaultConfig.get('/employee/active')
          .then(function (response) {
            self.isLoading = false;
            self.activeUsers = response.data.result;
          })
          .catch( function (err) {
            self.isLoading = false;
            console.log("error", err.message);
          })
      },
      getTemplateDetails(id) {
        this.isLoading = true;
        var self = this;
        defaultConfig.get(`/templates/${id}`)
          .then(function (response) {
            console.log('template details', response.data.result);
            self.isLoading = false;
            self.title = response.data.result[0].title;
            self.description = response.data.result[0].description;
            self.selectedSendingType = response.data.result[0].selectedSendingType;
            self.selectedAudienceType = response.data.result[0].selectedAudienceType;
            self.selectedUser = parseInt(response.data.result[0].selectedUser);
            self.attachmentName = response.data.result[0].attachmentName;
          })
          .catch( function (err) {
            self.isLoading = false;
            self.$refs.modal.title = "Ошибка!";
            self.$refs.modal.description = err.message;
            self.$refs.modal.isSuccessfull = false;
            self.$refs.modal.show = true;
            console.log("error", err);
          })
      },
      deleteTemplate(id) {
        this.isLoading = true;
        var self = this;
        defaultConfig.delete(`/templates/${id}`)
          .then(function (response) {
            console.log('template details', response.data.result);
            self.isLoading = false;
            self.$refs.modal.title = "Успешно!";
            self.$refs.modal.description = "Шаблон успешно удален!";
            self.$refs.modal.isSuccessfull = true;
            self.$refs.modal.show = true;
            setTimeout(() => {
              self.$router.go(-1);
            }, 1000);
          })
          .catch( function (err) {
            self.isLoading = false;
            self.$refs.modal.title = "Ошибка!";
            self.$refs.modal.description = err.message;
            self.$refs.modal.isSuccessfull = false;
            self.$refs.modal.show = true;
            console.log("error", err);
          })
      },
      saveTemplate(id) {
        this.v$.$validate();
        if (this.v$.$error) {
          this.$refs.modal.title = "Ошибка!";
          this.$refs.modal.description = "Не все обязательные поля заполены!";
          this.$refs.modal.show = true;
        } else if (this.selectedAudienceType == "2") {
          this.$refs.modal.title = "Ошибка!";
          this.$refs.modal.description = "К сожалению, отправка на группу рассылки пока не доступна. Функциональность появится позднее!";
          this.$refs.modal.show = true;
        } else if (this.selectedSendingType == 2 && (this.$refs.file.files.length == 0 && this.attachmentName == "")) {
          this.$refs.modal.title = "Ошибка!";
          this.$refs.modal.description = "При данном типе рассылке необходимо добавить вложение!";
          this.$refs.modal.isSuccessfull = false;
          this.$refs.modal.show = true;
        } else if (this.isNewAttachment && this.file.size >= this.maxFileSize) {
          this.$refs.modal.title = "Ошибка!";
          this.$refs.modal.description = "Превышен максимально допустимый размер файла (50 Мб)!";
          this.$refs.modal.isSuccessfull = false;
          this.$refs.modal.show = true;
        } else {
          this.isLoading = true;
          let formData = new FormData();
          if (this.isNewAttachment) {
            formData.append('attachment', this.file);
          }
          formData.append('isNew', this.isNewAttachment);
          formData.append('title', this.title);
          formData.append('description', this.description);
          formData.append('selectedSendingType', this.selectedSendingType);
          formData.append('selectedAudienceType', this.selectedAudienceType);
          formData.append('selectedUser', this.selectedUser);
          var config = {
            url: `http://sigmacapital.synology.me/api/v1/templates/${id}`,
            method: 'put',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          };
          var self = this;
          axios(config)
            .then(function (response) {
              self.isLoading = false;
              self.v$.$reset();
              self.$refs.modal.title = "Успешно!";
              self.$refs.modal.description = "Шаблон успешно сохранен!";
              self.$refs.modal.isSuccessfull = true;
              self.$refs.modal.show = true;
              setTimeout(() => {
                self.$router.go(-1);
              }, 1000);

              console.log("success", response.data);
            })
            .catch( function (err) {
              self.isLoading = false;
              self.$refs.modal.title = "Ошибка!";
              self.$refs.modal.description = err.message;
              self.$refs.modal.isSuccessfull = false;
              self.$refs.modal.show = true;
              console.log("error", err);
            })
        }
      },
    },
    validations() {
      return {
        title: { required: helpers.withMessage('Заголовок является обязательным полем.', required) },
        description: { required: helpers.withMessage('Сообщение является обязательным полем.', required) },
        selectedSendingType: { selectedSendingType: helpers.withMessage('Необходимо выбрать тип рассылки.', isSendingTypeSelected) },
        selectedAudienceType: { selectedAudienceType: helpers.withMessage('Необходимо выбрать аудиторию для рассылки.', isAudienceSelected) },
        selectedUser: { selectedUser: helpers.withMessage('Необходимо выбрать сотрудника для рассылки.', isUserSelected) },
      }
    },
  }
</script>
<style scoped>
  .fade-enter-active {
    transition: opacity 0.5s ease;
  }
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .errorBlock {
    color: red;
    font-size: small;
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile + label {
    cursor: pointer;
  }
  .attachment-label {
    font-size: medium;
    color: #fec64e;
  }
  .attachment-name {
    width: 100%;
  }
</style>