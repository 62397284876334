<template>
  <transition name="modal-fade">
    <div v-if="show" class="modal-overlay" @click.self="close">
      <div class="modal-window" @click.stop>
        <img :src="this.isSuccessfull ? success : failed" alt=""/>
        <h3>{{this.title}}</h3>
        <p>{{this.description}}</p>
        <select
          class="form-control select form-select templatable"
          id="dms"
          name="dms"
          v-model="selectedDmsType"
        >
          <option 
            v-for="option in this.options"
            v-bind:key="option.key"
            :value="option.key"
            :disabled="option.key == '0'"
            :hidden="option.key == '0'">
              {{option.name}}
          </option>
        </select>
        <template v:bind="this.userId"></template>
        <p class="errorBlock" v-if="v$.selectedDmsType.$error"> {{ v$.selectedDmsType.$errors[0].$message }} </p>
        <soft-button color="dark" variant="gradient" @click="saveDmsType(this.userId)">Сохранить</soft-button>
      </div>
    </div>
  </transition>
</template>

<script>
import SoftButton from "./SoftButton.vue";
import success from "@/assets/img/success-icon.png";
import failed from "@/assets/img/failed-icon.png";
import useValidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
const isDmsTypeSelected = (value) => value !== 0;

export default {
  name: "ActionModal",
  data() {
    return {
      v$: useValidate(),
      show: false,
      title: "",
      description: "",
      userId: "",
      selectedDmsType: 0,
      options: [],
      isSuccessfull: true,
      success,
      failed
    };
  },
  components: {
    SoftButton,
  },
  methods: {
    close() {
      this.show = false;
      this.title = "";
      this.description = "";
      this.userId = "";
      this.selectedDmsType = 0
    },
    saveDmsType(userId) {
      this.v$.$validate();
      if (this.v$.$error) {
        // do nothing
      } else {
        this.v$.$reset();
        this.$emit('save-dms-type', this.selectedDmsType - 1, userId);
        this.show = false;
        this.title = "";
        this.description = "";
        this.userId = "";
        this.selectedDmsType = 0;
      }
    },
  },
  validations() {
    return {
      selectedDmsType: { selectedDmsType: helpers.withMessage('Необходимо выбрать новый тип ДМС.', isDmsTypeSelected) },
    }
  },
}
</script>
<style scoped>
  .modal-overlay {
  position: fixed;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000da;
  z-index: 10000;
}
.modal-window {
  text-align: center;
  background-color: white;
  padding: 45px;
  max-width: 480px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
button {
  margin-top: 5%;
  width: 60%;
}
img {
  width:10%;
  margin-bottom: 5%;
}
h3,h5 {
  font-family: Gotham Pro;
}
.errorBlock {
  color: red;
  font-size: small;
}
</style>