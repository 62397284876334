<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <dms-users-table />
      </div>
    </div>
  </div>
</template>

<script>
import DmsUsersTable from "./components/DmsUsersTable";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "dmsUsers",
  data() {
    return {};
  },
  components: {
    DmsUsersTable,
  },
  mounted() {
    this.sidebarColor("dark");
    if (!localStorage.getItem('loggedIn')) {
      this.$router.push('/sign-in')
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor"]),

    sidebarColor(color = "dark") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },
  },
}
</script>