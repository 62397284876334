import { computed } from 'vue';

<template>
  <div class="tooltip-wrapper">
      <slot />
      <span class="tooltip__text tooltip--top">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "ToolTip",
  props: {
    text: {
      type: String,
      required: true
    },
  },
}
</script>
<style scoped>
.tooltip__text {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;
  color: #ffffff;
  font-size: 0.5em;
  text-align: center;
  padding: 5px 0;
  border-radius: 2px;
  min-width: 120px;
  background: #5e5d5d;
  position: absolute;
  z-index: 1;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}
.tooltip-wrapper:hover .tooltip__text{
  visibility: visible;
  opacity: 1;
}
.tooltip--top {
    inset-block-end: 120%;
    inset-inline-start: 50%;
    margin-inline-start: -60px;
}
</style>