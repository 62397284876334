<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-12 mb-lg-0">
          <div
            class="text-xs text-center copyright text-muted"
            :class="'text-lg-middle'"
          >
            Все права защищены © ООО "ДИАЛЛ АЛЬЯНС", 
            {{ new Date().getFullYear() }} г.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
