<template>
  <div class="card mb-4 mt-3">
    <div class="card-header pb-0">
      <h6>Список сотрудников (ДМС)</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2 pe-2">
      
      <div class="row pt-2 pb-2 ps-3 pe-1">
        <div class="col-6">
          <input
            type="text"
            class="form-control"
            placeholder="Поиск сотрудника..."
            v-model.trim="fullName"
          />
        </div>
        <div class="col-6">
          <div class="row pe-1">
            <div :class="selectedDmsType == 0 ? 'col-12' : 'col-11'">
              <select
                class="form-control select form-select templatable"
                id="sendingType"
                name="sendingType"
                v-model="selectedDmsType"
              >
                <option v-bind:value="0" disabled hidden>Фильтр по типу ДМС...</option>
                <option v-bind:value="1">Отсутствует</option>
                <option v-bind:value="2">ООО "Ингосстрах"</option>
                <option v-bind:value="3">ООО "Согласие"</option>
              </select>
            </div>
            <div v-if="this.selectedDmsType !== 0" class="col-1 cross">
              <span
                class="reset-value"
                @click="resetMessageForm">&#10006;</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ФИО
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Должность
              </th>
              <th 
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Тип ДМС
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user, index in filteredList"
              :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <soft-avatar
                        :img="avatar"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{user.fullName}}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{user.email}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="position text-xs font-weight-bold mb-0">{{user.position}}</p>
                  <p class="text-xs text-secondary mb-0">{{user.mobileNumber}}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <p v-if="user.dmsCompany != 'Отсутствует'" class="text-xs text-secondary mb-0">{{user.dmsCompany}}</p>
                  <img v-else class="mb-0" src="../../assets/default/new-item.png" width="60" height="60"/>
                </td>
                <td class="align-middle">
                  <ToolTip text="Сменить ДМС">
                    <button
                      id="dropdownMenuButton"
                      class="btn btn-link text-secondary mb-0"
                      aria-haspopup="true"
                      aria-expanded="false"
                      @click="showModal(user)"
                    >
                      <i class="fas fa-sliders-h poll-enabled" style="font-size: 20px;" aria-hidden="true"></i>
                    </button>
                  </ToolTip>
                </td>
                <td>
                  <ToolTip text="Назначить опрос">
                    <button
                      :disabled="user.isPollAvailable"
                      class="btn btn-link text-secondary mb-0"
                      @click="assignPoll(user.userId)">
                      <i class="fas fa-poll" :class="user.isPollAvailable ? 'poll-disabled' : 'poll-enabled'" style="font-size: 20px;"/>
                    </button>
                  </ToolTip>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal ref="modal" />
    <active-modal @save-dms-type="saveDmsType" ref="activeModal" />
    <loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import ToolTip from "../../helpers/ToolTip.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import Modal from "@/components/Modal.vue";
import ActiveModal from "@/components/ActionModal.vue";
import Loader from "@/components/Loader.vue";
import avatar from "../../assets/img/default-avatar.png";
import axios from 'axios';
import { defaultConfig } from "../../api/config.js";

export default {
  name: "dms-users-table",
  props: ["textWhite"],
  data() {
    return {
      avatar,
      initialUsersList: [],
      usersList: [],
      fullName: '',
      isLoading: false,
      dmsOptions: [
        { "key": 0, "name": "Выберите тип ДМС..." },
        { "key": 1, "name": "Отсутствует" },
        { "key": 2, "name": 'ООО "Ингосстрах"' },
        { "key": 3, "name": 'ООО "Согласие"' },
      ],
      selectedDmsType: 0,
    };
  },
  computed: {
    filteredList() {
      if (this.selectedDmsType !== 0) {
        return this.usersList.filter(el => {
          return (el.dmsType === this.selectedDmsType - 1) && (el.fullName.toLowerCase().indexOf(this.fullName.toLowerCase()) !== -1);
        })
      } else {
        return this.usersList.filter(el => {
          return el.fullName.toLowerCase().indexOf(this.fullName.toLowerCase()) !== -1;
        })
      }
    }
  },
  mounted() {
    this.getDmsEmployees();
  },
  components: {
    ToolTip,
    SoftAvatar,
    Modal,
    ActiveModal,
    Loader
  },
  methods: {
    getDmsEmployees() {
      this.isLoading = true;
      var self = this;
      defaultConfig.get('/dms')
        .then(function (response) {
          self.isLoading = false;
          self.usersList = response.data.result;
        })
        .catch( function (err) {
          self.isLoading = false;
          console.log("error", err.message);
        })
    },
    showModal(user) {
      this.$refs.activeModal.title = "ДМС!";
      this.$refs.activeModal.description = `Смена программы дмс для сотрудника: ${user.fullName}`;
      this.$refs.activeModal.options = this.dmsOptions;
      this.$refs.activeModal.userId = user.userId;
      this.$refs.activeModal.isSuccessfull = true;
      this.$refs.activeModal.show = true;
      console.log("toggle", `I am toggle dropdown for userId: ${user}`)
    },
    saveDmsType(dmsType, userId) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('selectedDmsType', dmsType);
      var config = {
        url: `http://sigmacapital.synology.me/api/v1/dms/${userId}`,
        method: 'put',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      var self = this;
      axios(config)
        .then(function (response) {
          self.isLoading = false;
          self.$refs.modal.title = "Успешно!";
          self.$refs.modal.description = "ДМС успешно обновлен!";
          self.$refs.modal.isSuccessfull = true;
          self.$refs.modal.show = true;
          setTimeout(() => {
            self.getDmsEmployees();
          }, 1000);
          console.log("success", response.data);
          console.log (`saved type: ${dmsType} for user: ${userId}`);
        })
        .catch( function (err) {
          self.isLoading = false;
          self.$refs.modal.title = "Ошибка!";
          self.$refs.modal.description = err.message;
          self.$refs.modal.isSuccessfull = false;
          self.$refs.modal.show = true;
          console.log("error", err);
        })
    },
    resetMessageForm() {
      this.selectedDmsType = 0;
    },
    assignPoll(userId) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('userId', userId);
      var config = {
        url: 'http://sigmacapital.synology.me/api/v1/dms/attach',
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      var self = this;
      axios(config)
        .then(function (response) {
          self.isLoading = false;
          self.$refs.modal.title = "Успешно!";
          self.$refs.modal.description = "Опрос по ДМС успешно назначен на сотрудника!";
          self.$refs.modal.isSuccessfull = true;
          self.$refs.modal.show = true;
          setTimeout(() => {
            self.getDmsEmployees();
          }, 1000);
          console.log("success", response.data);
        })
        .catch( function (err) {
          self.isLoading = false;
          self.$refs.modal.title = "Ошибка!";
          self.$refs.modal.description = err.message;
          self.$refs.modal.isSuccessfull = false;
          self.$refs.modal.show = true;
          console.log("error", err);
        })
    },
  },
}
</script>
<style scoped>
.position {
  word-break: normal;
  white-space: normal;
}
.reset-value {
  cursor: pointer;
}
.cross {
  transform: translate(-20%, 20%);
}
.poll-disabled {
  color: gray;
}
.poll-enabled {
  color: #0D488C;
}
</style>