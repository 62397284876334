<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-4 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-dark text-gradient">
                     Добро пожаловать
                  </h3>
                  <p class="mb-0">Введите логин и пароль для входа</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent>
                    <label>Адрес электронной почты</label>
                    <soft-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                    />
                    <label>Пароль</label>
                    <soft-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                    />
                    <soft-switch id="rememberMe" name="rememberMe" checked>
                      Запомнить меня
                    </soft-switch>
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="dark"
                        full-width
                        @click="login"
                        >Войти
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Еще нет логина и пароля?
                    <router-link
                      :to="'#'"
                      class="text-dark text-gradient font-weight-bold"
                      >Зарегистрируйтесь</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved15.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal ref="modal" />
  </main>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { defaultConfig } from "../api/config.js";
import Modal from "@/components/Modal.vue";
import logo from "@/assets/img/logoSmall.png";
import { mapMutations } from "vuex";

export default {
  name: "SignIn",
  data() {
    return {
      logo,
    };
  },
  components: {
    // Navbar,
    SoftInput,
    SoftSwitch,
    SoftButton,
    Modal,
  },
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  mounted() {
    if (localStorage.getItem('loggedIn')) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig", "saveUser"]),
    login() {
      const login = document.getElementById('email').value;
      const password = document.getElementById('password').value;

      if (login == ''|| password == '') {
        this.$refs.modal.title = "Ошибка!";
        this.$refs.modal.description = "Не все обязательные поля заполены!";
        this.$refs.modal.isSuccessfull = false;
        this.$refs.modal.show = true;
      } else {
        var self = this;
        
        defaultConfig.post('/user/login', { login: login, password: password })
          .then(function (response) {
            self.saveUser();
            self.$router.push('/dashboard');
            console.log("response", response);
          })
          .catch(function (err) {
            self.$refs.modal.title = "Ошибка!";
            self.$refs.modal.description = err.response.data ? err.response.data.errorMessage : "Что-то пошло не так! Попробуйте позднее или обратитесь к администратору ресурса.";
            self.$refs.modal.isSuccessfull = false;
            self.$refs.modal.show = true;
          })
      }
    },
  },
};
</script>