<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!-- line chart -->
        <div class="card z-index-2">
          <gradient-line-chart
            id="chart-line"
            title="Персональный ассистент работника"
            description="Количество зарегистрированных сотрудников"
            label="Зарегистрированные сотрудники"
            :labels="this.chartData.map(el => this.getPeriod(el.month, el.year))"
            :quantity="this.chartData.map(el => el.quantity)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <employee-table />
      </div>
    </div>
  </div>
</template>

<script>
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import EmployeeTable from "./components/EmployeeTable";
import { mapMutations, mapActions } from "vuex";
import { defaultConfig } from "../api/config.js";

export default {
  name: "dashboard",
  data() {
    return {
      chartData: [],
    };
  },
  components: {
    EmployeeTable,
    GradientLineChart
  },
  mounted() {
    this.sidebarColor("dark");
    if (!localStorage.getItem('loggedIn')) {
      this.$router.push('/sign-in')
    }
    this.getChartData();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor"]),

    sidebarColor(color = "dark") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },
    async getChartData() {
      this.isLoading = true;
      var self = this;
      defaultConfig.get('/charts')
        .then(function (response) {
          self.isLoading = false;
          self.chartData = response.data.result.chartData;
          console.log('chartData', self.chartData);
        })
        .catch( function (err) {
          self.isLoading = false;
          console.log("error", err);
        })
    },
    getPeriod(month, year) {
      switch(month) {
          case "01":
            return `Январь ${year}`;
          case "02":
            return `Февраль ${year}`;
          case "03":
            return `Март ${year}`;
          case "04":
            return `Апрель ${year}`;
          case "05":
            return `Май ${year}`;
          case "06":
            return `Июнь ${year}`;
          case "07":
            return `Июль ${year}`;
          case "08":
            return `Август ${year}`;
          case "09":
            return `Сентябрь ${year}`;
          case "10":
            return `Октябрь ${year}`;
          case "11":
            return `Ноябрь ${year}`;
          case "12":
            return `Декабрь ${year}`;
        }
    },
  },
};
</script>