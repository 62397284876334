import axios from 'axios'

export const defaultConfig = axios.create({
    baseURL: 'http://sigmacapital.synology.me/api/v1',
    timeout: 10000
})

export const formDataConfig = axios.create({
    baseURL: 'http://sigmacapital.synology.me/api/v1',
    timeout: 10000
})